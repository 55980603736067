import { gql, useQuery } from "@apollo/client";

const GET_WORKSPACE = gql`
  query getWorkspace($workspaceId: ID!) {
    workspace(id: $workspaceId) {
      id
      name
      teamsExternalMemberships {
        user {
          id
          name
        }
      }
      informationNeedsToBeUpdated
      provisioningOptionsAvailable
      legacyNetworkDirectoryPath
      legacySharedTeamsUrl
      endUserDirectoryPath
      networkDriveToolExternalLinkAvailable
      teamsToolExternalLinkAvailable
      plannerToolExternalLinkAvailable
      askiToolExternalLinkAvailable
      valueframeToolExternalLinkAvailable
      unit4ToolExternalLinkAvailable
      anyWorkspaceToolCompleted
      identifier
      description
      fullName
      lifecycleStatus
      isArchived
      initialized
      isUnit4
      usesLegacyLogic
      opportunityProjectStatus
      salesforceId
      serviceTypes {
        id
      }
      owners {
        id
        name
        o365Id
        email
      }
      members {
        id
        name
        o365Id
        email
      }
      owner {
        id
        name
        o365Id
      }
      opportunityProjectManager {
        id
        name
        o365Id
        email
      }
      projectManager {
        id
        name
        o365Id
        email
      }
      clientName
      clientRepresentativeName
      department {
        id
        code
        name
        businessAreaName
      }
      unitCode
      unitName
      riskLevel
      securityLevel
      deadlineAt
      generalTerms
      generalTermsTextOther
      hasTermsExceptions
      termsExceptions
      biddingType
      policy {
        create
        show
        update
        updateMembership
        updateWorksite
        serviceTypesAvailable
        reportServiceTypeAvailable
        showWorkspaceTools
        createRiskAssessments
        indexRiskAssessments
        createReclamations
        indexReclamations
        showResponsibilityForm
        updateResponsibilityForm
        createBidGroup
        indexBidGroup
        reimport
        indexChecklist
        indexSurvey
        createSurvey
        requestMembership
        manageMembershipRequests
      }
      worksites {
        id
        name
      }
      frameAgreement {
        id
        name
      }
      buildings {
        id
        name
        addresses {
          streetName
          streetNumber
          zipCode
          city
        }
        worksite {
          id
          name
          buildings {
            id
            name
            buildingIdentifier
            buildingUsageType
            addresses {
              id
              city
              streetName
              streetNumber
              zipCode
            }
          }
        }
      }
      subFrameAgreement {
        id
        name
        frameAgreement {
          id
          name
        }
      }
      referenceGroups {
        id
        name
      }
      workspaceServiceAreas {
        id
        name
        workspaceServiceTypes(workspaceId: $workspaceId) {
          id
          name
          position
          workspaceServiceProducts(workspaceId: $workspaceId) {
            id
            name
            position
          }
        }
      }
      productInput
      reportServiceType {
        id
        name
      }
      checklistGenerated
      restrictedFolderAccessMembers {
        id
        name
        o365Id
      }
      workorders {
        id
        name
        description
        identifier
        unit4Status
        valueframeId
        owner {
          name
          id
          email
        }
      }
      organization {
        unitCode
      }
      opportunityProjectDirectoriesLocation
      projectDirectoriesLocation
      projectStatus
      opportunityProjectStatus
      businessAreaName
      mainOfferWorkspace {
        id
        identifier
        name
        opportunityProjectManager {
          id
          name
          o365Id
          email
        }
      }
      offerWorkspaces {
        id
        identifier
        name
        opportunityProjectManager {
          id
          name
          o365Id
          email
        }
      }
      projectWorkspaces {
        id
        identifier
        name
        projectManager {
          id
          name
          o365Id
          email
        }
      }
      needFeedback
      noFeedbackReason
      mapLocationExists
      mapLocationAddress
      serviceProducts {
        id
        name
        serviceAreaId
        serviceType {
          id
          name
        }
        serviceTypeId
        slug
        updatedAt
      }
      unit4UpdatedAt
      talousdwUpdatedAt
      importerUnit4ProcessedAt
      importerTalousdwProcessedAt
      archivingStatus
      archivingStartsDate
      priceIncrementOption
      priceIncrementComment
      priceIncrementBase
      priceIncrementBaseOther
      priceIncrementDates {
        incrementDate
        notificationDates {
          notificationDate
        }
      }
    }
  }
`;

export function useWorkspace(workspaceId) {
  const { data, loading, error } = useQuery(GET_WORKSPACE, {
    variables: { workspaceId },
    skip: !workspaceId,
    fetchPolicy: "cache-and-network",
  });
  const areas = data?.workspace?.workspaceServiceAreas.map((area) => {
    const areaNew = {
      ...area,
      serviceTypes: area.workspaceServiceTypes.map((type) => {
        const typeNew = {
          ...type,
          serviceProducts: type.workspaceServiceProducts,
        };
        return typeNew;
      }),
    };
    return areaNew;
  });

  const workspaceNew = data?.workspace
    ? { ...data.workspace, serviceAreas: areas, type: "workspace" }
    : null;

  return { workspace: workspaceNew, loading, error };
}

export const UPDATE_WORKSPACE = gql`
  mutation UpdateWorkspace(
    $updateWorkspaceId: ID!
    $workspace: WorkspaceUpdateInputObject!
  ) {
    updateWorkspace(id: $updateWorkspaceId, workspace: $workspace) {
      id
      name
      fullName
    }
  }
`;

export const UPDATE_WORKSPACE_MEMBERSHIPS = gql`
  mutation UpdateWorkspaceMembership(
    $updateWorkspaceMembershipId: ID!
    $workspace: WorkspaceMembershipUpdateInputObject!
  ) {
    updateWorkspaceMembership(
      id: $updateWorkspaceMembershipId
      workspace: $workspace
    ) {
      id
      name
    }
  }
`;

export const UPDATE_WORKSPACE_WORKSITES = gql`
  mutation UpdateWorksite(
    $updateWorkspaceId: ID!
    $workspace: WorkspaceWorksiteUpdateInputObject!
  ) {
    updateWorksite(id: $updateWorkspaceId, workspace: $workspace) {
      id
      name
    }
  }
`;
